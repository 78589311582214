import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PackExToolbarAction } from './models/toolbar-action';
import { PackExToolbarUser } from './models/toolbar-user';

@Component({
  selector: 'packex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PackExToolbar implements OnInit {
  @Input('packex-action') actions: PackExToolbarAction[];
  @Input('packex-is-sidenav-open') isSideNavOpen = true;
  @Input('packex-has-sidenav-toggle') hasSidenavToggle = true;
  @Input('packex-navigate-back-url') navigateBackUrl: string | any[];
  @Input('packex-navigate-back-query-params') navigateBackQueryParams: {};
  @Input('packex-user') user: PackExToolbarUser;
  @Output('packex-action-emitter') actionEmitter = new EventEmitter<string>();
  @Output('packex-logout') logoutEmitter = new EventEmitter();
  @Output('packex-toggle-sidenav') toggleSideNavEmitter = new EventEmitter<boolean>(this.isSideNavOpen);
  @HostBinding('class.packex-sidenav')
  hostClass = true;
  hasLogoutSubscribers: boolean;
  hasToggleSidenavSubscribers: boolean;
  isMobile$: Observable<boolean>;

  constructor(
    private _breakpointObserver: BreakpointObserver
  ) {
    this.isMobile$ = this._breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).pipe(
      map(state => state.matches)
    );
  }
  ngOnInit() {
    this.hasToggleSidenavSubscribers = !!this.toggleSideNavEmitter.observers.length;
    this.hasLogoutSubscribers = !!this.logoutEmitter.observers.length;
  }
  emitToggleSideNav() {
    this.isSideNavOpen = !this.isSideNavOpen;
    this.toggleSideNavEmitter.emit(this.isSideNavOpen);
  }
  emitLogout() {
    this.logoutEmitter.emit();
  }
  emitAction(action: string) {
    this.actionEmitter.emit(action);
  }
}
