import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';

import { PackExButtonModule } from '../button/button.module';
import { PackExToolbar } from './toolbar.component';
import { PackExToolbarUserComponent } from './toolbar-user/toolbar-user.component';

@NgModule({
  declarations: [
    PackExToolbar,
    PackExToolbarUserComponent
  ],
  imports: [
    CommonModule,
    RouterModule,

    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    PackExButtonModule

  ],
  exports: [
    PackExToolbar,
    PackExToolbarUserComponent
  ]
})
export class PackExToolbarModule { }
