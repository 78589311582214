import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PackExFilterEntry } from './../filter-entry/filter-entry.component';

@Component({
  selector: 'packex-filter-container',
  templateUrl: './filter-container.component.html',
  styleUrls: ['./filter-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackExFilterContainer implements AfterContentInit, OnChanges {
  @ContentChildren(PackExFilterEntry) entryComponents: QueryList<PackExFilterEntry>;
  @Input('value') value: any;
  @Output('value-changed') valueChanged = new EventEmitter<any>();
  toggle: BehaviorSubject<number> = new BehaviorSubject(-5);

  @HostBinding('class.packex-filter-container')
  hostClass = true;

  ngAfterContentInit() {
    const entryArray = this.entryComponents.toArray();
    for (let i = 0; i < entryArray.length; i++) {
      const entry = entryArray[i];
      entry.isActive = this.value === entry.value;
      entry.activate$.pipe(
        tap(() => this.onActivate(i)),
        tap(value => this.valueChanged.emit(value))
      ).subscribe();
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.entryComponents && changes.value.currentValue !== changes.value.previousValue) {
      const index = this.entryComponents.toArray().findIndex(entry => entry.value === changes.value.currentValue);
      this.onActivate(index);
    }
  }

  onActivate(index: number) {
    const entryArray = this.entryComponents.toArray();
    for (let i = 0; i < entryArray.length; i++) {
      entryArray[i].isActive = i === index;
    }
  }
}
