// Write Tests
// Make Tooltip-examples
import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import tippy from 'tippy.js/dist/tippy-bundle.esm.js';

export type TooltipPosition = 'top' | 'top-start' | 'top-end' | 'right' | 'right-start' | 'right-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'left' | 'left-start' | 'left-end';
export type TooltipDelay = number | [number, number];
// TooltipTrigger should include all types + all combinations of types in a single string separated by a space
// export type TooltipTrigger = 'mouseenter' | 'focus' | 'focusin' | 'click' | 'manual';

export interface PackExTooltipDefaultOptions {
  hideDelay: number;
  maxWidth: number | string;
  position: TooltipPosition;
  showDelay: number;
  trigger: string;
}

export const DEFAULT_OPTIONS: PackExTooltipDefaultOptions = {
  position: 'top',
  maxWidth: 350,
  showDelay: 0,
  hideDelay: 0,
  trigger: 'mouseenter focus'
};

@Directive({
  selector: '[packexTooltip]',
  exportAs: 'packexTooltip'
})
export class PackExTooltipDirective implements OnInit {
  // A better way to achieve this, would be to import the css file as a string. But I didn't find any suitable solution.
  css = `.tippy-tooltip.packex-theme {
    background: rgba(255, 255, 255, 1);
    border-radius: 10px;
    box-shadow: 0px 0px 6px 1px #dad8d8;
    color: rgb(52,51,101);
    padding: 12px;
  }

  .tippy-tooltip.packex-theme[data-placement^='top'] .tippy-arrow {
    border-top-color: rgba(255, 255, 255, 1);
  }
  .tippy-tooltip.packex-theme[data-placement^='bottom'] .tippy-arrow {
    border-bottom-color: rgba(255, 255, 255, 1);
  }
  .tippy-tooltip.packex-theme[data-placement^='left'] .tippy-arrow {
    border-left-color: rgba(255, 255, 255, 1);
  }
  .tippy-tooltip.packex-theme[data-placement^='right'] .tippy-arrow {
    border-right-color: rgba(255, 255, 255, 1);
  }`;
  tippyInstance;
  @Input('packexTooltip') public content: string;
  @Input('packexTooltipPosition') position: TooltipPosition;
  @Input('packexTooltipMaxWidth') maxWidth: number | string;
  @Input('packexTooltipShowDelay') showDelay: number;
  @Input('packexTooltipHideDelay') hideDelay: number;
  @Input('packexTooltipTrigger') trigger: string;
  @Input('tippyOptions') tippyOptions: Object;

  constructor(private el: ElementRef) {
    this.el = el;
  }

  public ngOnInit() {
    this._setTippyOptions();
    this.tippyInstance = tippy(this.el.nativeElement, this.tippyOptions);
    this._injectCSS(this.css);
  }

  private _injectCSS(css) {
    const style = document.createElement('style');
    style.textContent = css;
    const head = document.head;
    head.appendChild(style);
  }

  private _setTippyOptions() {
    if (!this.tippyOptions) {
      // Set Delay
      const delay: TooltipDelay = [this.showDelay, this.hideDelay];
      if (!this.showDelay) {
        delay[0] = DEFAULT_OPTIONS.showDelay;
      }
      if (!this.hideDelay) {
        delay[1] = DEFAULT_OPTIONS.hideDelay;
      }

      this.tippyOptions = {
        content: this.content,
        delay: delay,
        ignoreAttributes: true, // Increases performance
        maxWidth: this.maxWidth || DEFAULT_OPTIONS.maxWidth,
        placement: this.position || DEFAULT_OPTIONS.position,
        theme: 'packex',
        trigger: this.trigger || DEFAULT_OPTIONS.trigger
      };
    }
  }

  public show() {
    this.tippyInstance.show();
  }

  public hide() {
    this.tippyInstance.hide();
  }

  public disable() {
    this.tippyInstance.disable();
  }

  public enable() {
    this.tippyInstance.enable();
  }
}
