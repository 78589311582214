import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { PackExToolbarUser } from '../models/toolbar-user';

@Component({
  selector: 'packex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackExToolbarUserComponent {
  @HostBinding('class.packex-toolbar-user')
  hostClass = true;
  @Input('packex-user') user: PackExToolbarUser;
}
